// ============================================================================
// Inner Page Layout
// ============================================================================

.content-block {
  margin: 20px auto;
  .block-image {
    margin: 0 auto;
    max-width: 300px;
    @media (min-width: $screen-sm-min) {
      max-width: 100%;
    }
  }
}