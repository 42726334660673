// ============================================================================
// Footer
// ============================================================================

.footer {
  padding-top: 40px;
  background-color: $theme-bg-primary;
  color: #FFF;
  .footer-block {
    margin: 20px 0 30px;
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
  .legal {
    text-align: center;
    padding: 40px 0 60px;
    margin: 15px 30px 0;
    border-top: 4px solid #FFF;
    .image {
      display: inline-block;
      margin-right: 10px;
    }
    .break {
      display: inline-block;
      @media (min-width: $screen-sm-min) {
        margin: 0 20px;
      }
    }
  }
  .fa {
    margin-bottom: 20px;
    @media (min-width: $screen-md-min) {
      float: right;
    }
  }
  h1 {
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    max-width: $container-lg;
    margin: 20px auto 40px;
    padding-bottom: 10px;
    border-bottom: 4px solid #FFF;
    @media (min-width: $screen-sm-min) {
      font-size: 57px;
      line-height: 58px;
    }
  }
  h5 {
    margin-top: 0;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    &.special {
      font-size: 12px;
      line-height: 14px;
      @media (min-width: $screen-sm-min) {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  a {
    transition: all ease 500ms;
    color: transparentize(#FFF, .5);
    &:hover {
      text-decoration: none;
      color: #FFF;
    }
  }
}
