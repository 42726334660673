// ============================================================================
// Typography Stylesheet
//
// Defines additional styles which can be applied in the CMS
// ============================================================================

// ATTENTION: Button styles are now in 'editor.scss' due to the unfortunate repercussions of using @extend

// Bootstrap Styles
// Styles which you want users to be able to select in the CMS
// ----------------------------------------------------------------------------

// For the style to register, it needs some kind of definition.
// Just apply the default display value

.lead {
  display: block;
}

.fake-heading {
  display: block;
}

h1, h2, h3, h4, h5, h6, p, li, span, {
  word-wrap: break-word;
}

main {
  h1 {
    text-align: center;
    text-transform: uppercase;
    color: $theme-primary;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    max-width: $container-lg;
    margin: 20px auto 40px;
    padding-bottom: 10px;
    border-bottom: 4px solid $theme-primary;
    @media (min-width: $screen-sm-min) {
      font-size: 70px;
      line-height: 80px;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    color: $theme-primary;
    font-size: 22px;
    line-height: 27px;
    font-weight: 600;
    @media (min-width: $screen-sm-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
    &.special {
      display: inline-block;
      font-weight: 100;
      text-transform: uppercase;
      font-size: 30px;
      @media (min-width: $screen-sm-min) {
        font-size: 48px;
      }
    }
  }
  h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    color: $theme-primary;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    @media (min-width: $screen-sm-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: 'Open Sans', sans-serif;
    color: $theme-primary;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    @media (min-width: $screen-sm-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
  }
  h5 {
    margin-top: 0;
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    color: $theme-primary;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    @media (min-width: $screen-sm-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
  }
  h6 {
    // font-size: ;
    // line-height: ;
    @media (min-width: $screen-sm-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-md-min) {
      // font-size: ;
      // line-height: ;
    }
    @media (min-width: $screen-lg-min) {
      // font-size: ;
      // line-height: ;
    }
  }
  ol {
    li {
      margin-bottom: 5px;
      font-family: 'Open Sans', sans-serif;
      color: $theme-primary;
      font-size: 15px;
      line-height: 24px;
    }
  }
  ul {
    li {
      margin-bottom: 5px;
      font-family: 'Open Sans', sans-serif;
      color: $theme-primary;
      font-size: 15px;
      line-height: 24px;
    }
  }
  p {
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    color: $theme-primary;
    font-size: 15px;
    line-height: 24px;
  }
  a {
    transition: all ease 500ms;
    color: transparentize($theme-primary, .1);
    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $theme-primary;
    }
  }
  hr {
    margin: 25px auto;
    border-top: 1px dashed #594f4f;
  }
}

// Custom Styles
//
// Styles which will appear in the Styles dropdown in the CMS
// ----------------------------------------------------------------------------

.no-break {
  white-space: nowrap;
}
