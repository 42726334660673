// ============================================================================
// SilverStripe Forms
// ============================================================================



// Primary Label
// ----------------------------------------------------------------------------

.requiredField {
  label.left {
    &:after {
      content: " *";
      color: $brand-danger;
    }
  }
}



// Right Label - convert to help text
// ----------------------------------------------------------------------------

form label.right {
  @extend .help-block;

  font-weight: normal;
}



// Fields
// ----------------------------------------------------------------------------

form .field {
  @extend .form-group;
  @extend .clearfix;
}



// Form Controls
// ----------------------------------------------------------------------------

form .field {
  input.text,
  select,
  textarea {
    @extend .form-control;

    height: auto;

    &.required {
      // This is a direct implementation of the Bootstrap form-control-required mixin

      border-color: $state-danger-text;

      @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075)); // Redeclare so transitions work

      &:focus {
        border-color: darken($state-danger-text, 10%);
        $shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($state-danger-text, 20%);
        @include box-shadow($shadow);
      }
    }
  }

  .readonly {
    @extend .form-control;
    @extend .form-control[disabled];
  }
}

// Checkbox
// ------------------------------------

form .field.checkbox {
  position: relative;

  padding-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;

  min-height: 0;

  input[type="checkbox"] {
    min-height: 0;
  }

  label.right {
    display: inline-block;
    margin: 0;
    padding: 0;

    color: $text-color;
  }

  label.required {
    position: absolute;

    top: 15px;
  }

  label.required + label {
    margin-bottom: 35px;
  }
}

// Checkbox Set
// ------------------------------------

form .field.optionset {
  .optionset {
    padding: 0;
    margin: 0;

    list-style: none;
  }

  li {
    display: block;

    padding-left: 20px;
    min-height: 20px;
    margin-top: 10px;
    margin-bottom: 10px;

    input[type="checkbox"],
    input[type="radio"] {
      display: block;
      float: left;

      margin-left: -20px;
    }

    input[type="radio"] {
      margin-top: 2px;
    }

    label {
      margin: 0;

      font-weight: normal;
    }
  }
}

// Date
// ------------------------------------

form .field.date {
  .middleColumn {
    @include clearfix();

    input.date {
      @extend .col-sm-2;
    }
  }
}



// Validation Messages
// ----------------------------------------------------------------------------

form {
  p.message {
    @extend .alert;

    &.bad {
      @extend .alert-danger;
    }

    &.good {
      @extend .alert-success;
    }

    &.warning {
      @extend .alert-warning;
    }
  }

  label.required,
  .message.validation {
    @extend .help-block;

    color: $state-danger-text;
    font-weight: normal;
  }
}

// Actions
// ----------------------------------------------------------------------------

form .Actions {
  input[type="reset"],
  input[type="submit"] {
    @extend .btn;
  }

  input[type="submit"] {
    @extend .btn-primary;
  }
}
