.carousel {
  .carousel-inner {
    .item {
      img {
        width: 100%;
      }
    }
  }
  .carousel-indicators {
    li {
      margin: 0 2px -20px;
      width: 20px;
      height: 20px;
      border: 0;
      background-color: transparentize($theme-primary, .5);
      &.active {
        width: 20px;
        height: 20px;
        margin: 0 2px -20px;
        background-color: $theme-primary;
      }
    }
  }
  .carousel-control {
    z-index: 1000;
    opacity: 1 !important;
    background: none !important;
    i, span {
      color: $theme-primary;
      font-size: 40px;
    }
    &.right {
      .glyphicon {
        right: 40px !important;
      }
    }
    &.left {
      .glyphicon {
        left: 40px !important;
      }
    }
  }
}

.carousel-caption {
  left: 0;
  right: 0;
  bottom: initial;
  top: 50%;
  -webkit-transform: translateY(-50%); // Force iPad
  transform: translateY(-50%);
  background-color: rgba(255,255,255,.8);
  .content  {
    margin: 20px;
    padding: 20px;
    border-top: 4px solid $theme-primary;
    border-bottom: 4px solid $theme-primary;
    * {
      text-shadow: none;
      color: $theme-primary;
    }
    h1, .fake-heading {
      margin: 0;
      border: 0;
      color: $theme-primary;
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      @media (min-width: $screen-md-min) {
        font-size: 70px;
        line-height: 80px;
      }
    }
    p {
      font-size: 20px;
      line-height: 24px;
      font-weight: 300;
      @media (min-width: $screen-md-min) {
        font-size: 25px;
        line-height: 39px;
      }
    }
  }
}

.mobile-captions {
  h1 {
    margin: 10px auto;
    text-align: left;
    line-height: 36px;
    font-weight: 300;
    font-size: 28px;
    border: 0;
  }
}