// ============================================================================
// Navbar
// ============================================================================

.navbar {
  // Collapsed Styles
  // background-color: ; // Navbar background colour
  // border-color: ; // Navbar border colour
  background-color: #FFF;
  border-radius: 0;
  border-color: transparent;
  margin-bottom: 0;
  @media (min-width: $grid-float-breakpoint) {
    min-height: 105px;
  }
  .navbar-inner {
    @media (min-width: $grid-float-breakpoint) {
      margin: 0 120px;
    }
  }
  .navbar-header {
    height: auto;
    button {
      margin: 20px 15px 0;
      border: 0;
      background-color: transparent;
      &:hover, &:focus, &:active {
        background-color: transparent;
      }
      .icon-bar {
        height: 4px;
        background-color: #000;
      }
    }
  }
  .navbar-brand {
    padding: 15px 15px 0;
    @media (min-width: $grid-float-breakpoint) {
      padding-top: 20px;
    }
    img {
      max-width: 160px;
      @media (min-width: $grid-float-breakpoint) {
        max-width: 260px;
      }
    }
  }
  .navbar-nav {
    margin-top: 25px;
    a {
      transition: all ease 500ms;
      text-transform: uppercase;
    }
    li {
      a {
        // color: ; // Navbar link colour
        // background-color: ; // Navbar link background colour
        &:hover, &:focus, &:active {
          color: #FFF; // Navbar link colour when hovered/focused/activated
          background-color: $theme-primary; // Navbar link background colour when hovered/focused/activated
        }
      }
      &.current, &.active {
        a {
          color: $theme-primary; // Navbar link colour when current (SS)
          background-color: transparent; // Navbar link background colour when current (SS)
          &:hover, &:focus, &:active {
            color: $theme-primary; // Navbar link colour when hovered/focused/activated and current (SS)
            background-color: transparent; // Navbar link background colour when hovered/focused/activated and current (SS)
          }
        }
      }
    }
    .open {
      a {
        // color: ; // Navbar link colour when open
        // background-color: ; // Navbar link background colour when open
        &:hover, &:focus, &:active {
          // color: ; // Navbar link colour when open and hovered/focused/activated
          // background-color: ; // Navbar link background colour when open and hovered/focused/activated
        }
      }
      .dropdown-menu {
        // background-color: ; // Navbar dropdown background colour
        // border-color: ; // Navbar dropdown border colour
        li {
          a {
            // background-color: ; // Navbar dropdown link background colour
            // border-color: ; // Navbar dropdown link Navbar border colour
            &:hover, &:focus, &:active {
              // color: ; // Navbar dropdown link colour when hovered/focused/activated
              // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated
            }
          }
          &.current, &.active {
            a {
              // color: ; // Navbar dropdown link colour when current (SS)
              // background-color: ; // Navbar dropdown link background colour when current (SS)
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    // Expanded Styles
    // background-color: ; // Navbar background colour
    // border-color: ; // Navbar border colour
    .navbar-nav {
      li {
        a {
          // color: ; // Navbar link colour
          // background-color: ; // Navbar link background colour
          padding: 10px 15px 5px;
          border-bottom: 2px solid $theme-primary;
          font-size: 18px;
          line-height: 24px;
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when hovered/focused/activated
            // background-color: ; // Navbar link background colour when hovered/focused/activated
          }
        }
        &.current, &.active {
          a {
            color: #FFF; // Navbar link colour when current (SS)
            background-color: $theme-primary; // Navbar link background colour when current (SS)
            &:hover, &:focus, &:active {
              color: #FFF; // Navbar link colour when hovered/focused/activated and current (SS)
              background-color: $theme-primary; // Navbar link background colour when hovered/focused/activated and current (SS)
            }
          }
        }
      }
      .open {
        a {
          // color: ; // Navbar link colour when open
          // background-color: ; // Navbar link background colour when open
          &:hover, &:focus, &:active {
            // color: ; // Navbar link colour when open and hovered/focused/activated
            // background-color: ; // Navbar link background colour when open and hovered/focused/activated
          }
        }
        .dropdown-menu {
          // background-color: ; // Navbar dropdown background colour
          // border-color: ; // Navbar dropdown border colour
          li {
            a {
              // background-color: ; // Navbar dropdown link background colour
              // border-color: ; // Navbar dropdown link Navbar border colour
              &:hover, &:focus, &:active {
                // color: ; // Navbar dropdown link colour when hovered/focused/activated
                // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated
              }
            }
            &.current, &.active {
              a {
                // color: ; // Navbar dropdown link colour when current (SS)
                // background-color: ; // Navbar dropdown link background colour when current (SS)
                &:hover, &:focus, &:active {
                  // color: ; // Navbar dropdown link colour when hovered/focused/activated and current (SS)
                  // background-color: ; // Navbar dropdown link background colour when hovered/focused/activated and current (SS)
                }
              }
            }
          }
        }
      }
    }
  }
}