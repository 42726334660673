// ============================================================================
// Scaffolding
// ============================================================================

body {
    padding-top: 75px;
  @media (min-width: $grid-float-breakpoint) {
    padding-top: 105px;
  }
}

a[data-scroll] {
  cursor: pointer;
}

.strong {
  font-weight: bold;
}

.fake-link {
  color: #0000FF;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.hover-wrapper {
  position: relative;
  margin: 10px auto;
  text-align: center;
  max-width: 300px;
  @media (min-width: $screen-sm-min) {
    max-width: 100%;
  }
  .img-responsive {
    margin: 0 auto;
  }
  .overlay {
    transition: all ease 500ms;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  h3 {
    transition: all ease 500ms;
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    text-transform: uppercase;
    color: #FFF;
  }
  &:hover {
    .overlay {
      background-color: rgba(0, 0, 0, .4);
    }
    h3 {
      opacity: 1;
    }
  }
}

.breaker {
  height: 200px;
  margin-top: -100px; // because iphones don't know how to cover
  background-attachment: scroll;
  background-position: 50% 100px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: $screen-sm-min) {
    margin-top: 20px;
    height: 350px;
  }
  @media (min-width: $screen-lg-min) {
    background-attachment: fixed;
  }
  &.swing {
    background-image: url('/themes/default/images/swing_bg.jpg');
  }
  &.pasta {
    background-image: url('/themes/default/images/pasta_bg.jpg');
  }
}

.restricted {
  max-width: $screen-lg;
}