// ============================================================================
// Home Page Layout
// ============================================================================

.commitment {
  background-image: url(/themes/default/images/commitment_bg.jpg);
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 80px auto 40px;
  @media (min-width: $screen-sm-min) {
    background-attachment: fixed;
  }
  .content {
    padding: 20px;
    @media (min-width: $screen-sm-min) {
      padding: 40px 60px;
    }
    p {
      font-size: 20px;
      line-height: 32px;
    }
  }
}

.distributors {
  padding: 60px 0;
  background-color: $theme-bg-secondary;
  .distributor {
    margin-bottom: 40px;
  }
  h1, h4, h5, p {
    color: #FFF;
    border-color: #FFF;
  }
  a {
    color: transparentize(#FFF, .5);
    &:hover, &:active, &:focus {
      color: #FFF;
    }
  }
}