.fade {
  padding: 0 !important;
  background-color: transparent;
  background-image: url("/themes/default/images/modal_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.modal {
  .modal-dialog {
    width: 80%;
    max-width: 700px;
    margin: 10px auto;
    @media (min-width: $screen-sm-min) {
      margin-top: 120px;
    }
    .modal-content {
      border-radius: 0;
      .modal-header {
        border: 0;
        .img-responsive {
          margin: 10px auto;
          max-width: 100px;
          @media (min-width: $screen-sm-min) {
            margin: 20px auto;
            max-width: 175px;
          }
        }
      }
      .modal-body {
        margin: 0 60px;
        border-top: 2px solid $theme-primary;
        .btn-theme-primary, select {
          display: block;
          width: 100%;
          margin-bottom: 15px;
        }
        .btn-theme-primary {
          padding: 7px 5px;
        }
        select {
          padding: 10px 20px;
          border: 1px solid #7E7171;
          background-color: rgba(229, 227, 227, 1);
        }
        h4 {
          text-transform: uppercase;
          color: $theme-primary;
          font-size: 15px;
          @media (min-width: $screen-sm-min) {
            font-size: 18px;
          }
        }
      }
    }
  }
}