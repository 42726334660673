// ============================================================================
// Wine Page Layout
// ============================================================================

.pairing {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 2px solid $theme-primary;
  @media (min-width: $screen-sm-min) {
    min-height: 540px;
  }
  h3 {
    a {
      font-weight: 600;
    }
  }
}

.details {
  @media (min-width: $screen-sm-min) {
    margin: 40px 80px 0;
  }
}

.carousel-nav {
  @extend .list-unstyled;
  display: inline-block;
  li {
    display: inline-block;
    a {
      display: block;
      padding: 5px 15px;
      text-transform: uppercase;
      color: $theme-primary;
      &.active {
        text-decoration: underline;
      }
      &:hover, &:focus, &:active {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.owl-carousel {
  margin: 40px auto;
  max-width: calc(100% - 40px);
  @media (min-width: $screen-sm-min) {
    max-width: calc(100% - 160px);
  }
  div[data-hash] {
    //cursor: pointer;
    .content {
      padding-right: 10px;
      h1 {
        margin: 0 auto 10px;
        text-align: left;
        text-transform: uppercase;
        font-weight: 400;
        font-style: normal;
        border: 0;
        line-height: 24px;
        font-size: 18px;
        @media (min-width: $screen-lg-min) {
          line-height: 36px;
          font-size: 28px;
        }
      }
    }
  }
  .owl-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
    display: none;
    @media (min-width: $screen-sm-min) {
      display: block;
    }
    .owl-prev, .owl-next {
      background-color: transparent;
      &:hover, &:active, &:focus {
        background-color: transparent;
      }
      i {
        font-size: 40px;
        color: $theme-primary;
      }
    }
    .owl-prev {
      float: left;
      margin-left: -60px;
    }
    .owl-next {
      float: right;
      margin-right: -60px;
    }
  }
}