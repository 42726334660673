// ============================================================================
// Buttons
// ============================================================================

.btn-theme-primary {
  @extend .btn;
  white-space: normal !important;
  word-wrap: break-word;
  transition: all ease 500ms;
  border-radius: 0;
  background-color: $theme-primary;
  color: #FFF;
  font-size: 20px;
  padding: 10px 25px;
  display: block;
  width: 100%;
  @media (min-width: $screen-sm-min) {
    display: inline-block;
    width: auto;
  }
  &:hover, &:focus, &:active {
    background-color: darken($theme-primary, 15%);
    color: #FFF;
    outline: 0;
  }
}